.container {
  margin: 5px;
  width: 100%;
  text-align: center;

  .card {
    border: #C3D5E4 1px solid;
    margin: 5px;
    cursor: pointer;
  }

  .footerContainer {
    width: 100%;
    margin-top: 10px;
    .footer {
      .centerPanel {
        text-align: center;
      }
    }
  }
}
