.container {
  margin: 30px;

  .title {
    font-size: 1.5em;
  }

  .table {
    tr {
      th {
        width: 100px;
      }
    }
  }

  .button {
    margin: 5px;
  }
}
