.dataContainer {
  align-content: center;
  .table {
    margin-left: auto;
    margin-right: auto;
    border: #C3D5E4 1px solid;
    th {
      border: #C3D5E4 1px solid;
      text-align: center;
    }
    td {
      border: #C3D5E4 1px solid;
      text-align: left;
      padding-left: 10px;
    }
  }
}

.button {
  margin-left: 5px;
  margin-right: 5px;
}
